import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const VideoBG = ({ video }) => {
  let videoitem = useRef(null)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (videoitem) {
      videoitem.addEventListener('loadeddata', () => {
        setLoading(false)
      })
    }

    return () => {
      if (videoitem) {
        videoitem.removeEventListener('loadeddata', () => {})
      }
    }
  })

  return (

    <video
      muted
      playsInline
      autoPlay
      loop
      id='videobg'
      ref={ref => (videoitem = ref)}
      style={{
        opacity: loading ? 0 : 1,
        transition: 'opacity, 2s ease-in-out',
      }}
    >
      {video.map((videoitem, i) => (
        <source key={'backgroundvideo-' + i} src={videoitem.url} type={videoitem.type} />
      ))}

    </video>

  )
}

VideoBG.propTypes = {
  video: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
}

export default VideoBG
